module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kenif's blog","short_name":"kenifs-blog","description":"\"Welcome to my blog! I'm Kenneth, a computer science student and founder of BotPowered Solutions. We create software services for thousands of Singaporeans. My expertise includes full-stack development, data analytics & business intelligence, and system design & infrastructure. I've worked with technologies like Node.js, React, MongoDB, BeautifulSoup, and AWS. Some of my notable projects include Rain Korkor, a Telegram bot for weather updates, CarSmart, a SaaS platform for car listings, and Checkpoint Jam, a Telegram bot for traffic updates.\"","start_url":"/","background_color":"#fff","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
