import github from "prism-react-renderer/themes/github"
import vsDark from "prism-react-renderer/themes/vsDark"
import { themeWithCssVariables } from "./prism-utils"

var { theme: lightTheme, variables: lightThemeVars } = themeWithCssVariables(github)
var { theme: darkTheme, variables: darkThemeVars } = themeWithCssVariables(vsDark)

// change plain-backgroundColor value of lightThemeVars object
// lightThemeVars["comment-color"] = `#000`
darkThemeVars["comment-color"] = '#6B9A56'
lightThemeVars["comment-color"] = '#CCCCCC'
lightThemeVars["plain-color"] = '#F49725'
lightThemeVars["keyword-color"] = '#E64200'
lightThemeVars["function-color"] = '#4095A8'
// lightThemeVars["class-color"] = '#4095A8'
lightThemeVars["tag-color"] = '#2194F0'
lightThemeVars["plain-background"] = '#FEF8EC'

// --theme-ui-colors-background
// darkThemeVars[]
// console.log(darkThemeVars, lightThemeVars)
// console.log(darkTheme.styles, lightTheme.styles)

export { lightTheme, darkTheme, lightThemeVars, darkThemeVars }
